html,
body,
.appLoading {
  height: 100%;
}

.appLoading {
  background: black;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  flex-direction:column;
}

.spinner {
  height: 30px;
  width: 30px;
  display: flex;
  -webkit-animation: rotation 0.7s infinite linear;
  -moz-animation: rotation 0.7s infinite linear;
  -o-animation: rotation 0.7s infinite linear;
  animation: rotation 0.7s infinite linear;
  border-left: 5px solid rgba(0, 175, 233, 0.15);
  border-right: 5px solid rgba(0, 175, 233, 0.15);
  border-bottom: 5px solid rgba(0, 175, 233, 0.15);
  border-top: 5px solid rgba(0, 175, 233, 0.8);
  border-radius: 100%;
}

.spinner_2022 {
  margin-top: 40px;
  height: 60px;
  width: 60px;
  display: flex;
  -webkit-animation: rotation 0.7s infinite linear;
  -moz-animation: rotation 0.7s infinite linear;
  -o-animation: rotation 0.7s infinite linear;
  animation: rotation 0.7s infinite linear;
  border-left: 5px solid rgba(0, 142, 146, 0.15);
  border-right: 5px solid rgba(0, 142, 146, 0.15);
  border-bottom: 5px solid rgba(0, 142, 146, 0.15);
  border-top: 5px solid rgba(0, 142, 146, 0.8);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
