.mui-form{
    padding: 50px;
    background-color:white;
}
.css-1wazku3-MuiTypography-root{
    font-weight:700 !important;
}

.css-1xqrenu-MuiButtonBase-root-MuiButton-root{
    color:rgba(0, 0, 0, 0.54) !important
}

.css-1qua55p{
    padding-top:30px !important;
}

.service-grid-space{
    margin-top:23px;
}